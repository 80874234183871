import ToastificationContent from "@core/components/toastification/ToastNotification";

export function debounce(fn, delay) {
    let timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        let args = arguments
        let that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}

import ToastNotification from '@core/components/toastification/ToastNotification.vue'


export function showToast(variant, title, icon, text) {
    this.$toast({
        component: ToastNotification,
        props: {
            title: title,
            icon: icon,
            variant,
            text: text,
        },
    })
}


export function formatDateWithDot(rawDate) {
    const date = new Date(rawDate)
    const year = date.getFullYear()
    const baseMonth = date.getMonth() + 1
    const month = baseMonth < 10 ? `0${baseMonth}` : baseMonth
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    return `${day}.${month}.${year}`
}
