<template>
    <b-row>

        <div class="d-flex justify-content-between align-items-center col-12 mb-2" style="gap:.5rem">
            <h2 class="pl-1">{{ $t('roles_permission.titles.cardLog') }}</h2>

            <!--            RETRY SEND-->
            <b-button v-if="reSendPermission" @click="retrySend" variant="outline-success">
                <feather-icon
                    icon="RefreshCwIcon"
                    size="21"
                />
            </b-button>

        </div>


        <!--  TABLE  -->
        <b-col cols="12">
            <b-table
                striped
                hover
                responsive
                sort-icon-left
                :busy="isBusy"
                :items="items"
                :fields="fields"
            >

                <template v-slot:head()="data">
                    {{ $t(`${data.label}`) }}
                </template>


                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-1">{{ $t('loading') }}</strong>
                    </div>
                </template>


            </b-table>
        </b-col>

        <!--  PAGINATION  -->
        <b-col
            cols="12"
        >
            <b-pagination
                v-if="showPagination"
                v-model="pagination.current"
                :total-rows="pagination.totalItems"
                :per-page="pagination.perPage"
                align="center"
                size="sm"
                class="mt-0"
            ></b-pagination>
        </b-col>


    </b-row>
</template>

<script>
import {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    BCard,
    BFormTextarea,
} from 'bootstrap-vue'
import api from '@/services/api'
import Ripple from 'vue-ripple-directive'
import ToastNotification from '@core/components/toastification/ToastNotification.vue'
import {formatDateWithDot} from "@/util/helper";
import CardPermission from "@/permission/card";

export default {
    name: "CardLogs",
    components: {
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BSpinner,
        BCard,
        BFormTextarea,
        ToastNotification,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            isBusy: false,
            // sortBy: '',
            // sortDesc: false,
            // sortDirection: 'asc',
            searchQuery: this.$route.query.search,
            filterOn: [],
            fields: [
                {
                    key: 'user_id',
                    label: 'cardLog.user_id',
                },
                {
                    key: 'action',
                    label: 'cardLog.action',
                },
                {
                    key: 'status',
                    label: 'status',
                },
                {
                    key: 'time',
                    label: 'time',
                    formatter: (time) => {
                        return formatDateWithDot(time)
                    }
                },
                {
                    key: 'data.X-CORRELATION-ID',
                    label: 'cardLog.correlation',
                    tdClass: 'fixWidth'
                },
                {
                    key: 'data.response.message',
                    label: 'cardLog.message',
                    tdClass: 'fixWidth'
                },
            ],
            items: [],
            loading: false,
            pagination: {
                current: 1,
                perPage: 10
            },
            reSendPermission: CardPermission.getCardResendVisaPermission(),
        }
    },
    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page
                this.getCardLogsList()
            },
            deep: true,
        },
        'pagination.current': {
            handler(page) {
                this.replaceRouter({
                    ...this.query,
                    page,
                })
            },
        },
        'pagination.perPage': {
            handler(perPage) {
                let page = this.pagination.current
                if (this.pagination.current > this.pagination.total) {
                    page = 1
                }
                this.replaceRouter({
                    ...this.query,
                    limit: perPage,
                    page: page
                })
            },
        },
        searchQuery: {
            handler(value) {
                if (value && value.length > 2) {
                    this.replaceRouter({
                        ...this.query,
                        search: value
                    })
                } else {
                    const hasSearchField = this.query.hasOwnProperty('search')
                    if (hasSearchField) {
                        delete this.query['search']
                        this.replaceRouter({
                            ...this.query,
                        })
                    }
                }
            },
        }
    },

    async created() {
        await this.getCardLogsList()
    },

    computed: {
        query() {
            return Object.assign({}, this.$route.query)
        },

        hasItems() {
            return this.items && this.items.length > 0
        },

        showPagination() {
            return this.hasItems && !this.isBusy
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key,
                }))
        },
    },

    methods: {
        showToast(variant, text, icon) {
            this.$toast({
                component: ToastNotification,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            })
        },

        replaceRouter(query) {
            this.$router.replace({query})
                .catch(() => {
                })
        },

        async getCardLogsList() {
            const cardId = this.$route.params.id
            this.isBusy = true
            await api.cardLogs.getCardLogs(cardId, this.query)
                .then(res => {
                    this.items = res.data.items
                    this.pagination = res.data.pagination
                })
                .catch(() => {
                    this.showToast('danger', 'Что-то пошло не так!', 'XIcon')
                })
                .finally(() => {
                    this.isBusy = false
                })
        },

        retrySend() {
            const cardId = this.$route.params.id
            api.cardLogs.getCardRetryVisa(cardId).then(() => {
                this.$router.push({name: 'cards'})
            })
        }


    },
}
</script>

<style lang="scss">

.validation__red {
    color: red;
    font-size: 12px;
    display: block;
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 6px;
}

.crud__btn .btn {
    padding: 0.6rem;
}

.fixWidth {
    min-width: 150px;
}

</style>
